const DEV_BASE_URL = '/dev'
const STAGING_BASE_URL = '/staging'
const PRODUCTION_BASE_URL = ''

export const ENV = process.env.GATSBY_ENV

export let BASE_URL: string
if (ENV === 'production') {
  BASE_URL = PRODUCTION_BASE_URL
} else if (ENV === 'staging') {
  BASE_URL = STAGING_BASE_URL
} else {
  BASE_URL = DEV_BASE_URL
}
