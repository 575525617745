import crossFetch from 'cross-fetch'
import Cookies from 'js-cookie'

import {BASE_URL} from '../constants/env'

export interface CSRFTokenOptions {
  url: string
  cookieName: string
  tokenRequestPromise: Promise<Response> | undefined
}

export const getCSRFToken = (csrfTokenOptions: CSRFTokenOptions) => async () => {
  let currentToken = Cookies.get(csrfTokenOptions.cookieName)
  if (currentToken === undefined) {
    const timeout = Date.now() + 5000 // timeout after 5 seconds
    // make request to get the csrftoken
    if (!csrfTokenOptions.tokenRequestPromise) {
      csrfTokenOptions.tokenRequestPromise = crossFetch(csrfTokenOptions.url, {credentials: 'include'})
    }
    await csrfTokenOptions.tokenRequestPromise
    // wait (up until timeout) for cookie to be set
    while ((currentToken = Cookies.get(csrfTokenOptions.cookieName)) === undefined) {
      if (Date.now() > timeout) {
        break
      }
    }
  }
  return currentToken
}

export const makeApiCsrfTokenOptions = (BASE_URL: string) => ({
  cookieName: 'csrftoken',
  tokenRequestPromise: undefined,
  url: `${BASE_URL}/csrf`,
})

// Perform GET requests to get CSRF tokens if unset
export const fetchCSRFTokensOnLoad = (csrfTokenFetchPromises: (() => Promise<string | undefined>)[]) => {
  if (typeof document !== 'undefined') {
    document.addEventListener(
      'DOMContentLoaded',
      function () {
        csrfTokenFetchPromises.forEach(fetchPromise => fetchPromise())
      },
      false,
    )
  }
}

const apiCSRFTokenOptions = makeApiCsrfTokenOptions(BASE_URL)
export const getApiCSRFToken = getCSRFToken(apiCSRFTokenOptions)
