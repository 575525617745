type ServiceEnv = 'development' | 'production' | 'staging'

const gatsbyServiceEnv = process.env.GATSBY_ENV
const defaultServiceEnv = 'development'

export const SERVICE_ENV = [gatsbyServiceEnv, defaultServiceEnv].find(
  value => typeof value !== 'undefined',
) as ServiceEnv

const DEV_BASE_URL = '/dev'
const STAGING_BASE_URL = '/staging'
const PRODUCTION_BASE_URL = ''

export let BASE_URL: string
if (SERVICE_ENV === 'production') {
  BASE_URL = PRODUCTION_BASE_URL
} else if (SERVICE_ENV === 'staging') {
  BASE_URL = STAGING_BASE_URL
} else {
  BASE_URL = DEV_BASE_URL
}
