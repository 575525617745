import gql from 'graphql-tag'
import React from 'react'

import client from '../../gatsby-theme-apollo/client'

export const TOKEN_QUERY = gql`
  query ($token: String!) {
    checkApplicantToken(token: $token)
  }
`

/**
 * Remove the `token=` parameter value from the URL and return it.
 *
 * @returns The token obtained from the URL parameter `token=`, or '' by default.
 */
export function popTokenFromURL(): string {
  if (typeof window === 'undefined') return '' // Handle SSR, where there is no window.
  // get the token from the URL query string
  const params = new URLSearchParams(document.location.search.substring(1))
  const token = params.get('token') || ''

  // Return early if there was no token param.
  if (token === '') return token

  // remove the token from the URL query string
  params.delete('token')
  // replace the history state so the token is not visible via browser history
  window.history.replaceState({}, '', `${location.pathname}?${params}`)
  return token
}

/**
 * Get the `token=` parameter value from the URL and return it.
 *
 * @returns token obtained from the URL parameer `token=`
 */
export function getTokenFromURL(): string {
  // get the token from the URL query string
  const params = new URLSearchParams(document.location.search.substring(1))
  const token = params.get('token') || ''
  return token
}

/**
 * Provide the user's magic link token as a hook for React components.
 *
 * @param refetchOnAuth Set to true by default. Generally, we want to reset the store after
 * determining if the user is logged in. Sometimes we don't want to refire those queries
 * because they're not authenticated, as on /tempe/apply.
 * @returns current React hook state containing the magic link token
 */
export default function useApplicantURLToken(refetchOnAuth = true) {
  // store the token from the URL as state that the component consumes
  const [token, setToken] = React.useState('')

  // remove the token from the URL and update the hook's state
  React.useEffect(() => {
    setToken(getTokenFromURL())
  }, []) // only perform this side effect once per render

  // send the token to the server to log the user in
  React.useEffect(() => {
    // Don't call if no token.
    if (token) {
      client
        .query({
          fetchPolicy: 'network-only',
          query: TOKEN_QUERY,
          variables: {token},
        })
        .finally(() => {
          // Allow us to avoid refetching if the page isn't authed.
          if (refetchOnAuth) {
            void client.resetStore() // re-run all queries after signing in
          }
          popTokenFromURL()
        })
    }
  }, [token]) // eslint-disable-line

  return token
}
