// src/gatsby-theme-apollo/client.js
import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {ErrorResponse, onError} from '@apollo/client/link/error'
import {RetryLink} from '@apollo/client/link/retry'
import {ServerError} from '@apollo/client/link/utils'
import {createMockClient} from '@apollo/client/testing'
import {popTokenFromURL} from '@components/rentalApplication/useApplicantURLToken'
import * as Sentry from '@sentry/gatsby'
import {fetchCSRFTokensOnLoad, getApiCSRFToken} from '@shared-utils/csrfTokenUtils'
import crossFetch from 'cross-fetch'
import gql from 'graphql-tag'

import {BASE_URL} from '../utils/constants'
import {selectedUnit} from './cache'

export const MAGIC_LINK_TOKEN_HEADER = 'X-MagicLinkToken'

// https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#defining
export const cache = new InMemoryCache({
  typePolicies: {
    AddressType: {
      keyFields: ['uuid'],
    },
    ApplicantType: {
      merge: true,
    },
    ApplicationType: {
      keyFields: ['uuid'],
    },
    BuildingType: {
      keyFields: ['uuid'],
    },
    // Type policy map
    Client: {
      fields: {
        // Field policy map for the RentalApplication type
        selectedUnit: {
          // Field policy for the selectedUnit field
          read() {
            // The read function for the selectedUnit field
            return selectedUnit()
          },
        },
      },
    },

    EventType: {
      fields: {after: {keyArgs: ['number']}, before: {keyArgs: ['number']}},
      keyFields: ['uuid'],
    },

    LeaseType: {
      keyFields: ['uuid'],
    },

    MaintenanceRequestType: {
      keyFields: ['ticketId'],
    },

    OfficeRnDUserType: {
      keyFields: ['memberId'],
    },

    PropertyImageType: {
      keyFields: ['uuid'],
    },
    Query: {
      fields: {
        event: {
          read(_, {args, toReference}: any) {
            return toReference({
              __typename: 'EventType',
              uuid: args.uuid,
            })
          },
        },
        floorPlan: {
          read(_, {args, toReference}: any) {
            if (!args.uuid) return
            return toReference({
              __typename: 'UnitFloorplanType',
              uuid: args.uuid,
            })
          },
        },
      },
    },

    ServiceAgreementAcceptanceType: {
      keyFields: ['uuid'],
    },

    StripeUserType: {
      keyFields: ['customerId'],
    },

    TenantAgreementType: {
      keyFields: ['uuid'],
    },

    TenantType: {
      merge: true,
    },

    UnitFeatureType: {
      keyFields: ['uuid'],
    },

    UnitFloorplanFeatureType: {
      keyFields: ['uuid'],
    },

    UnitFloorplanType: {
      keyFields: ['uuid'],
    },

    UnitListingType: {
      keyFields: ['uuid'],
    },

    UnitType: {
      keyFields: ['uuid'],
    },
    UserType: {
      fields: {
        applicant: {
          merge: true,
        },
      },
      keyFields: ['uuid'],
    },
  },
})

// generate unique transactionId and set as Sentry tag
export const transactionId = Math.random().toString(36).substr(2, 9)

if (Sentry) {
  Sentry.configureScope(scope => {
    scope.setTag('transaction_id', transactionId)
  })
}

/**
 *
 * @param obj The ApolloLink context object, passed by a previous Apollo Link.
 * @param obj.headers The existing headers, which will be spread into a new headers object.
 * @returns The ApolloLink context with updated headers.
 */
export const createCSRFHeaders = async ({headers = {}}: Record<string, any>): Promise<Record<string, string>> => ({
  headers: {
    ...headers,
    'X-CSRFToken': await getApiCSRFToken(),
  },
})

/**
 * Based on https://www.apollographql.com/docs/react/networking/advanced-http-networking/#customizing-request-logic
 */
export const magicTokenMiddleware = new ApolloLink((operation, forward) => {
  // add the magic link token to the headers
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      [MAGIC_LINK_TOKEN_HEADER]: popTokenFromURL(),
    },
  }))

  return forward(operation)
})

export const sentryTracingMiddleware = new ApolloLink((operation, forward) => {
  // Add Sentry transaction_id to headers for tracing.
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      'X-Transaction-ID': transactionId,
    },
  }))

  return forward(operation)
})

export const csrfTokenHeaderLink = setContext(createCSRFHeaders)

export const httpLink = new HttpLink({
  credentials: 'include',
  fetch: crossFetch,
  uri: `${BASE_URL}/graphql`,
})

export function handleApolloErrors(error: ErrorResponse) {
  if (Sentry && error.graphQLErrors !== undefined) {
    error.graphQLErrors.forEach(error => {
      Sentry.captureMessage(error.message)
    })
  }

  if (error.networkError !== undefined) {
    const networkError = error.networkError as ServerError
    Sentry.captureException(networkError)
  }
}

const retryLink = new RetryLink()

export const errorLink = onError(handleApolloErrors)

export const completeLink = from([csrfTokenHeaderLink, httpLink])

export const liveClientConfig = {
  cache,
  link: from([retryLink, errorLink, csrfTokenHeaderLink, magicTokenMiddleware, sentryTracingMiddleware, httpLink]),
}

const client = (function () {
  // TODO setup a proper MockProvider instead of using throwaway a mock client here
  if (process.env.NODE_ENV === 'test') {
    const throwawayMockQuery = gql`
      query {
        users {
          uuid
        }
      }
    `
    return createMockClient({}, throwawayMockQuery)
  } else {
    /* istanbul ignore next */
    return new ApolloClient(liveClientConfig)
  }
})()

fetchCSRFTokensOnLoad([getApiCSRFToken])

export default client
