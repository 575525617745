/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // Import our global styles, including those from shared components.
import './src/styles/index.scss';
import React from 'react'
import {QueryParamProvider} from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

// When the user goes to an internal route - https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.page();
  }
}

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE) and react-intersection-observer
  if (!(`IntersectionObserver` in window)) {
    await import('intersection-observer')
  }
  const smoothscroll = await import('smoothscroll-polyfill')
  smoothscroll.polyfill()
  // Set up Datadog RUM
  // Based on https://github.com/niklasnordlund/gatsby-plugin-datadog/blob/c0ceac5/gatsby-browser.js
  const ddBrowserRUM = await import('@datadog/browser-rum')
  ddBrowserRUM.datadogRum.init({
    applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID,
    clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask',
    enableExperimentalFeatures: ["feature_flags"],
    enabled: process.env.GATSBY_ENV === 'production',
    env: process.env.GATSBY_ENV,
    service:'culdesac-web',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: process.env.COMMIT_REF
  })
  window.datadogRum = ddBrowserRUM.datadogRum
  // Start Datadog session recording
  window.datadogRum && window.datadogRum.startSessionReplayRecording()
  window.datadogRum && window.datadogRum.addFeatureFlagEvaluation('v2-self-serve-leasing', process.env.BRANCH === 'abtest/self-serve-v2')
}

export const wrapPageElement = ({element}) => <QueryParamProvider adapter={ReachAdapter}>{element}</QueryParamProvider>
export const shouldUpdateScroll = ({routerProps, prevRouterProps}) =>
  // if the previous route's pathname is the same as the current route's pathname,
  // that means only query params have changed, so we don't want to scroll
  // to the top of the page when the URL changes
  !prevRouterProps?.location.pathname || routerProps?.location.pathname !== prevRouterProps?.location.pathname
